<template>
  <button
    class="text-base p-2 hover:bg-gray-200 rounded transition-colors duration-300 group"
    :class="{ 'flex items-center w-full': label }"
  >
    <fa-icon
      :icon="[iconStyle, icon]"
      class="fa-fw text-gray-500 group-hover:text-blue-500 transition-colors duration-300"
    />
    <span v-if="label" class="whitespace-no-wrap ml-4">{{ label }}</span>
  </button>
</template>

<script>
export default {
  props: {
    iconStyle: {
      type: String,
      default: 'fas'
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    }
  }
};
</script>
