<template>
  <span>{{ localDate }}</span>
</template>

<script>
import dayjs from '@/plugins/dayjs';

export default {
  props: {
    date: {
      type: String,
      default: null
    }
  },
  computed: {
    localDate() {
      if (!this.date) {
        return '-';
      }

      return dayjs
        .utc(this.date)
        .local()
        .format('DD-MM-YYYY HH:mm:ss');
    }
  }
};
</script>
